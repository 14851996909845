import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import { Layout, Menu } from "antd";
import { BookOutlined } from "@ant-design/icons";

const { Sider } = Layout;

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: { published: { eq: true }, pages: { eq: "content" } }
            # fields: { slug: { regex: "/content-articles/.*/" } }
          }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                pages
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <Link to={`/articles/`}>
          <h2 style={{ textAlign: "center", marginTop: 20 }}>
            <BookOutlined /> Articles
          </h2>
        </Link>

        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div>
            <Menu theme="light">
              <Menu.Item key={node.id}>
                <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
              </Menu.Item>
            </Menu>
          </div>
        ))}
      </Sider>
    )}
  />
);
