import React from "react";
import { graphql } from "gatsby";
import { Layout } from "antd";
import CommonHeader from "../components/common/header";
import CommonFooter from "../components/common/footer";
import ArticleSider from "../components/article/article-sider";
import Helmet from "react-helmet";

const { Content } = Layout;

export default ({ data }) => {
  const post = data.markdownRemark;
  return (
    <div>
      <Helmet>
        <title>{post.frontmatter.title} - DayViewer</title>
      </Helmet>
      <Layout>
        <CommonHeader />
        <Layout style={{ minHeight: "100vh" }}>
          <ArticleSider />
          <Content>
            <div
              className="content"
              style={{ padding: "8%", fontSize: "16px" }}
            >
              <h1>{post.frontmatter.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </Content>
        </Layout>
        <CommonFooter />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
